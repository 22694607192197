@import "../../variables.css";

.wrapper {
    color: var(--default-color);
    text-align: justify;

    .required {
        color: rgb(164, 38, 44);
    }

    .label {
        display: block;
        font-weight: 600;
        color: rgb(50, 49, 48);
        padding: 5px 0;

        &::after {
            content: " *";
            color: rgb(164, 38, 44);
            padding-right: 12px;
        }
    }

    ul {
        display: flex;

        &.tabs {
            margin: 0;
            padding: 0;
            list-style: none;
            border-radius: 2px 2px 0 0;

            li {
                background: transparent;
                color: #444;
                display: inline-block;
                padding: 10px 0;
                cursor: pointer;
                flex: 1;
                text-align: center;
                font-weight: 600;

                &.current {
                    background: #3F84E5;
                    color: #FFF;
                }
            }
        }
    }

    .tab-content {
        display: none;
        padding: 15px;

        &.current {
            display: inherit;
            background: #FFF;
        }

        i {
            font-weight: bold;

            &::before {
                margin-right: 5px;
            }
        }

        input[type=text],
        input[type=password],
        input[type=email],
        input[type=tel],
        input[type="number"],
        select {
            background: #f1f1f1;
            width: 100%;
            padding: 10px 0;
            /* margin-bottom: 10px; */
            border: none;
            /* text-indent: 60px; */
            text-indent: 5px;

            &:focus {
                outline-color: #3F84E5;
            }
        }

        select {
            border: 1px solid #000;
            border-radius: 2px;
        }

        input[type=password] {
            margin-bottom: 0;
        }

        input[type=submit] {
            background: #3F84E5;
            border: none;
            padding: 10px 0;
            text-align: center;
            width: 100%;
            color: #FFF;
            font-weight: 700;
            cursor: pointer;
            transition: 0.s ease;

            &:hover {
                background: #2980b9;
            }
        }

        .input-icon {
            position: absolute;
            margin-top: 10px;
            margin-left: 14px;
            font-size: 17px;

            &:after {
                content: "";
                height: 35px;
                width: 1px;
                background: #ddd;
                position: absolute;
                margin-top: -8px;
                left: 30px;
            }
        }

        .btn-container {
            margin-top: 10px;
        }

        .btn-icon {
            position: absolute;
        }

        .selection-payment {
            text-align: center;
        }
    }
}
