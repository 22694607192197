@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .card {
        height: 100%;
        border: none;
        border-radius: 0;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border: 1px solid #000;

        input {
            height: 10px;
            width: 40px;
            padding: 10px 10px;
            border-radius: 2px;
            border: 1px solid #d6d1d5;
            margin-top: 5px;
            margin-left: 5px;

            &:focus {
                outline-color: #3F84E5;
            }
        }

        .label {
            padding: 3px 5px;
            background-color: #C17817;
            color: #FFF;
        }

        .slider {
            li {
                cursor: zoom-in;
            }

            img {
                height: 170px;
                max-width: 100% !important;
                width: none !important;
                user-select: none;
                cursor: zoom-in;
            }
        }
    }

    .noStock {
        opacity: 0.2;

        .noStockLabel {
            color: var(--red-color);
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .hiddenBackground {
        background: none;
        border: none;
    }

    .inline {
        display: inline;
    }

    .manageCart {
        font-size: 10px;
    }

    button {
        cursor: pointer;
        margin: 15px;
    }

    button:disabled {
        cursor: not-allowed;
    }

    .btnMinus {
        color: #B20D30;
        font-size: 12px;
        opacity: 2;
    }

    .btnPlus {
        color: #3F784C;
        font-size: 12px;
        opacity: 2;
    }
}
