@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .full-card {
        border: 1px solid var(--gray-color);

        .thumbnail {
            position: relative;
            width: 100%;
            height: 187px;
            overflow: hidden;
            border-bottom: 2px solid var(--gray-color);
            transition: all 0.2s;

            &:hover {
                animation: soften 0.2s;
            }

            img {
                position: absolute;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
            }

            .no-picture {
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: #CCC /* var(--default-color) */;
            }
        }

        .description {
            padding: 10px;
            font-weight: bold;
            background-color: #FFF;
        }
    }
}
