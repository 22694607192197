@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .cart {
        font-size: 60px;
    }

    .inline > * {
        display: inline-block;
        margin: 5px;
        font-size: 12px;
    }

    .bold {
        font-weight: bold;
        width: 80%;
    }

    .separator {
        border-bottom: 1px solid #D3D3D3;
    }

    .nextStep {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
    }

    .lastStep {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        background-color: #FFFFFF;
        color: black;
        border: 1px solid black;
    }

    .footerForm {
        margin-top: 50px;
        text-align: center;
    }

    .product-container {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding: 5px 7px 15px 7px;
        background-color: rgb(242, 242, 242, 0.3);
    }

    .container-writing-mode {
        max-width: 400px;
        width: 100%;
        margin: 30px auto 0;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .bckIcon {
        font-size: 20px;
        margin-right: 10px;
    }

    .validBtn {
        font-size: 30px;
    }

    .validIcon {
        margin-right: 20px;
    }

    input[type="submit"] {
        cursor: pointer;
        margin-top: 20px;
    }

    input[class="payboxBtn"] {
        font-size: 20px;
        background-color: #3F84E5;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        border: 1px solid #3F84E5;
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", "Helvetica Neue", sans-serif;
        width: 100%;
        max-width: 25%;
        height: 37px;

        &:hover {
            background: #2980b9;
        }
    }
}


