@import "../../variables.css";

.wrapper {
    color: var(--default-color);
    margin-bottom: 25px;

    h1 {
        display: inline-block;
        padding: 12px;
        margin: 0;

        i {
            padding: 8px;
            margin-right: 15px;
            color: #FFF;
        }
    }
}
