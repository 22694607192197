@import "@csstools/normalize.css";
@import "./variables.css";
@import url("https://fonts.googleapis.com/css?family=Lora:400,700|Titillium+Web:400,700");
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Oswald:wght@300&display=swap');
/* @import-normalize; */

:global(body) {
    font-family: "Titillium Web", Arial, Sans-Serif;
    font-size: var(--font-size-base);
    color: var(--default-color);
}

.wrapper {
    h1 {
        font-size: var(--font-size-h1);
    }

    .bloc {
        padding-left: 0;
        padding-right: 0;
        box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
        
        section {
            margin: 45px 25px;
        }
    }
}
