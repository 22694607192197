@import "../../variables.css";

.wrapper {
    color: var(--default-color);
    
    .fix-header {
        width: 100%;
        text-align: center;

        nav {
            overflow: hidden;
            background-color: var(--gray-color);

            button {
                transition: all 0.2s;

                &:hover {
                    opacity: 0.8;
                }
            }

            img.flag {
                position: relative;
                height: 25px;
                z-index: 10;
                cursor: pointer;

                &.leftFlag {
                    margin-right: -26px;
                }

                &.rightFlag {
                    margin-left: -10px;
                }
            }
        }
    }

    .illustration {
        padding: 10px;
        border: 2px solid var(--gray-color);
        border-bottom-width: 4px;

        img {
            width: 100%;
            min-height: 150px;
            user-select: none;
            opacity: 0.9;
            border-radius: 4px;
        }
    }
}

.sticky-header {
    position: relative;
    /* margin-top: -28px; */
    width: 100%;
    overflow: hidden;
    text-align: center;
    background: #FFF;
    z-index: 999;

    .current-route {
        border-color: var(--red-color);
        background-color: var(--red-color);
        color: #FFF;

        &:hover {
            opacity: .8;
            background-color: var(--red-color);
        }
    }

    > a {
        display: inline-block;
        padding: 0 15px;
        line-height: 45px;
        color: #454551;
        font-size: 12px;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .2s;

        &:first-child {
            margin-right: 15px;
        }

        &:last-child {
            margin-left: 15px;
        }

        &:hover {
            background-color: #e9e9e9;
        }

        i {
            margin-right: 5px;
        }
    }
}
