@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .container {
        max-width: 400px;
        width: 100%;
        margin: 40px auto;
        padding: 15px;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

        a {
            display: inline-block;
            background: #3F84E5;
            border: none;
            margin: 10px 0;
            padding: 10px 0;
            text-align: center;
            width: 100%;
            color: #fff;
            font-weight: 700;
            text-decoration: none;
            transition: 0.s ease;

            &:hover {
                background: #2980b9;
            }
        }

        .response-container {
            padding: 15px 0 5px;
            text-align: center;

            img {
                width: 80px;
                margin-bottom: 10px;
                opacity: 0.8;
                filter: grayscale(1);
            }

            hr {
                width: 65%;
                opacity: 0.5;
            }
        }
    }
}
