@import "../../../variables.css";

.wrapper {
    color: var(--default-color);

    .favorite {
        position: absolute;
        top: 3px;
        left: 5px;
        color: var(--red-color);
        font-size: 15px;
    }

    .calendar-container {
        display: table;
        vertical-align: middle;
        padding: 0;

        .calendar {
            position: relative;
            max-width: 120px;
            margin: 0 auto;
            background-color: var(--gray-color);
            border: 1px solid #FFF;

            .calendar-day {
                text-align: center;
                font-size: 35px;
                font-weight: bold;
                padding-top: 5px;
            }

            .calendar-separator {
                width: 35%;
                text-align: center;
                font-size: 25px;
                color: #686868;
            }

            .calendar-month {
                margin: 5px 0;
                text-transform: uppercase;
                font-size: 18px;
                text-align: center;
                color: #686868;
            }
        }
    }
}
