@import "../../variables.css";

.wrapper {
    position: relative;
    top: 0;
    text-align: center;
    background-color: var(--gray-color);

    .sub-wrapper {
        h4 {
            color: var(--default-color);
        }

        ul {
            padding-left: 0;

            li {
                display: inline-block;
                height: 18px;
                width: calc(100%);
                list-style-type: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                a {
                    text-decoration: none;
                    color: var(--default-color);
                    font-size: 13px;
                    padding: 0 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    nav {
        width: 100%;
        line-height: 60px;
        text-align: right;
        background-color: #e9e9e9;

        a {
            margin: 0 15px;
            color: var(--default-color);
            font-size: 13px;
        }

        span {
            margin: 0 15px;
        }
    }

    @media only all and (max-width: 640px) {
        ul {
            display: inline-block;
        }
    }
}
