@import "../../variables.css";

.wrapper {
    color: var(--default-color);
    margin: 10px -8px;
    border: 2px solid var(--gray-color);
    background-color: var(--gray-color);

    .favorite {
        position: absolute;
        top: 5px;
        left: 15px;
        color: var(--red-color);
        font-size: 18px;
    }

    .container {
        .thumbnail-container {
            position: relative;
            height: 100px;
            overflow: hidden;
            background: #CCC;

            img {
                width: 100%;

                &.small {
                    height: 100%;
                }

                &.big {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);

                    &:hover {
                        animation: MoveUpDown 6s linear infinite;
                    }
                }
            }
        }

        .identifier-container {
            height: 100px;
            display: table;
            padding: 5px;

            .pastille {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                border-radius: 6%;

                .identifier {
                    display: inline-block;
                }
            }

            .identifier {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 10px;
                hyphens: auto;
            }

            .identifier-present {
                color: #FFF;
            }

            .missing-identifier {
                color: var(--default-color);
            }
        }

        .description-container {
            height: 100px;
            padding: 25px;

            h5 {
                margin: 0;

                &.date {
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    font-weight: normal;
                }
            }

            h3 {
                margin: 0;

                &.title {
                    font-size: 18px;
                }
            }
        }

        .player-container {
            display: table;
            height: 100px;
        }
    }
}

@keyframes MoveUpDown {
    0% {
        top: 50%;
    }

    25% {
        top: 25%;
    }

    75% {
        top: 75%;
    }

    100% {
        top: 50%;
    }
}
