@import "../../variables.css";

.slick-dots {
    bottom: inherit !important;
}

.wrapper {
    color: var(--default-color);
    text-align: center;
    height: 405px;

    &.is-mobile {
        height: auto;
    }
    
    img {
        max-height: 400px;
        width: auto !important;
    }

    .slider-container {
        width: 100%;
        display: inline-block;

        .slider {
            width: 80%;
            margin: 0 auto;

            button::before {
                color: var(--default-color);
            }
        }

        .pagination {
            display: inline-block;
            padding: 5px 15px;
            border: 1px solid var(--default-color);
        }
    }
}
