@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .title {
        text-align: center;
        margin-bottom: 35px;
    }

    img {
        cursor: zoom-in;
    }

    .container {
        padding: 25px auto;
        filter: sepia(20%);
        text-align: justify;
        line-height: 20px;

        h1 {
            display: inline-block;
            width: 60%;
            margin: 35px 0 45px;
            text-transform: uppercase;
            font-size: 35px !important;
        }

        a {
            line-height: 40px;
            font-size: 20px;
            text-decoration: none;
            color: var(--red-color);

            img {
                float: left;
                height: 40px;
                margin-right: 6px;
            }
        }
    }

    .inactive {
        display: none;
    }
}
