@import "../../variables.css";

.wrapper {
    color: var(--default-color);
    
    a {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;

        &:hover {
            opacity: 0.8;
        }

        &:first-child, &:last-child {
            transition: all 0.2s;
        }

        &:first-child {
            img {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }
        }

        &:last-child {
            img {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;;
            }
        }
    }

    img.flag {
        position: relative;
        height: 25px;
        margin-top: 18px;
        z-index: 10;
        cursor: pointer;

        &.leftFlag {
            margin-right: -26px;
        }

        &.rightFlag {
            margin-left: -10px;
        }
    }
}
