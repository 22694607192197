@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    ul {
        padding: 0;

        li {
            margin: 5px 0;
            list-style-type: none;

            a {
                display: inline-block;
                color: #000;
                text-decoration: none;

                table {
                    padding: 0;
                    border-collapse: collapse;

                    tbody {
                        td:nth-child(1) {
                            padding: 0 5px;
                            width: 50px;
                            text-align: center;

                            i {
                                position: relative;
                                top: 2px;
                                font-size: 20px;
                                color: var(--red-color);

                                small {
                                    font-size: 8px;
                                    display: inline-block;
                                }
                            }
                        }

                        td:nth-child(2) {
                            padding: 0;
                            border: 1px solid var(--default-color);

                            i {
                                padding: 5px;
                                color: #FFF;
                            }
                        }

                        td:nth-child(3) {
                            padding: 0 5px;
                            border: 1px solid var(--default-color);
                        }
                    }
                }
            }
        }
    }

    :local {
        .blink {
            animation: blinker 1.5s linear infinite;
        }

        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
    }
}
