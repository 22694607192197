@import "../../variables.css";

.wrapper {
    table {
        padding: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        background-color: rgb(242, 242, 242, 0.3);

        td, th {
            text-align: center;
            border: 1px solid var(--gray-color);
        }
    
        thead {
            tr {
                background-color: var(--gray-color);
    
                td, th {
                    padding: 15px;
                }
            }
        }

        tbody {
            th:nth-child(1),
            td:nth-child(1) {
                background-color: #d3d2cd;
            }
        }
    
        h1 {
            font-family: "Arial, Helvetica, sans-serif";
            margin-top: 50px;
            margin-bottom: 50px;
        }
    
        img {
            height: 100px;
        }
    }

    .inline {
        display: inline;
    }

    .manageCart {
        font-size: 10px;
        margin: 30px;
    }

    .hiddenBtn {
        display: none;
    }

    .hiddenBackground {
        background: none;
        border: none;
    }

    button:disabled {
        cursor: not-allowed;
    }

    button {
        cursor: pointer;
    }
}
