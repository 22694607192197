@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    a {
        color: var(--red-color);
        text-decoration: none;
    }
}
