@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .paybox-container {
        display: none;
    }

    .container {
        max-width: 400px;
        width: 100%;
        margin: 40px auto;
        overflow: hidden;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

        &.mobile {
            width: 100%;
            max-width: none;
            margin: 0;
        }

        .container-writing-mode {
            background: #FFF;
        }

        .success {
            padding: 15px;
            text-align: center;

            input[type=submit] {
                background: #3F84E5;
                border: none;
                margin: 25px 0 10px;
                padding: 10px 0;
                text-align: center;
                width: 100%;
                color: #FFF;
                font-weight: 700;
                cursor: pointer;
                transition: 0.s ease;

                &:hover {
                    background: #2980b9;
                }
            }
        }

        /* .success {
            padding: 15px 0 5px;
            text-align: center;

            img {
                width: 80px;
                margin-bottom: 10px;
                opacity: 0.8;
                filter: grayscale(1);
            }

            hr {
                width: 65%;
                opacity: 0.5;
            }
        } */
    }
}
