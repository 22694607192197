@import "../../variables.css";

.wrapper {
    color: var(--default-color);

    .ms-Grid-col {
        border: 2px solid red;
    }

    .bloc {
        margin: 10px 0;

        .parent-container {
            height: 100px;

            .description-container {
                padding: 25px;
                background-color: var(--gray-color);
                border: 1px solid #FFF;

                .title {
                    margin-bottom: 6px;
                    font-weight: bold;
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    a {
                        color: var(--default-color);
                        text-decoration: none;
                        transition: .3s all;

                        &:hover {
                            color: var(--red-color);
                        }
                    }
                }

                .description {
                    display: inline-block;
                    height: 18px;
                    width: calc(100%);
                    margin-top: 3px;
                    font-size: 13px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    p {
                        height: 18px;
                        margin-top: 0px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: var(--default-color);
                        text-decoration: none;
                    }

                    h1, h2, h3, h4, h5, h6 {
                        margin: 0;
                        font-weight: normal;
                        color: var(--default-color);
                        text-decoration: none;
                    }

                    img {
                        display: none;
                    }
                }
            }

            .thumbnail-container {
                margin: 0;
                padding: 0;
                border: 1px solid #FFF;
                overflow: hidden;
                background: #CCC;

                img {
                    width: 100%;

                    &.small {
                        height: 100%;
                    }

                    &.big {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        
                        &:hover {
                            animation: MoveUpDown 6s linear infinite;
                        }
                    }
                }
            }

            .icon-container {
                display: table;
                border: 1px solid #FFF;

                .icon {
                    display: table-cell;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 40px;
                    color: #FFF;
                    opacity: 0.6;
                }
            }
        }
    }
}

@keyframes MoveUpDown {
    0% {
        top: 50%;
    }

    25% {
        top: 25%;
    }

    75% {
        top: 75%;
    }

    100% {
        top: 50%;
    }
}
