:root {
    --font-size-base: 16px;
    --font-size-h1: 28px;
    --font-size-h2: 24px;
    --font-size-h3: 20px;
    --font-size-h4: 16px;
    --font-size-h5: 14px;

    --default-color: #616161;
    --gray-color: #f2f2f2;
    --red-color: #a4373a;
}

.loader-container {
    text-align: center;
}

.hidden-ninja {
    display: none !important;
}

.full-height {
    height: 100%;
}
